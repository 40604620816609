export const Features = ({ features }) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title text-center">
              <h2>What We Do</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {features?.map((d, i) => (
            <div key={`${d.title}-${i}`} className="col-sm-6 col-md-3">
              {" "}
              <i className={d.icon}></i>
              <h3>{d.title}</h3>
              <p>{d.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
